import { render, staticRenderFns } from "./AllOrder.vue?vue&type=template&id=6fffcb7a&scoped=true&"
import script from "./AllOrder.vue?vue&type=script&lang=js&"
export * from "./AllOrder.vue?vue&type=script&lang=js&"
import style0 from "./AllOrder.vue?vue&type=style&index=0&id=6fffcb7a&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6fffcb7a",
  null
  
)

export default component.exports